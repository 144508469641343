$(document).ready(function () {
	
	if($('#slideshow').length > 0){
		var slideshow = $('#slideshow')
		
		if(slideshow.children().length <= 1){
			$('#slideshowControls, #slideshowNav').hide();
		}
		
		slideshow.cycle();
			
		/* Sequential image loading to reduce amount of data transfered on load */
		slideshow.on( 'cycle-before', function(e, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag ) {
			nextImg = $('#slideshow img').eq(optionHash.nextSlide + 1);
			if ( nextImg.attr("src") == "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=="){
				nextImg.attr("src", nextImg.attr("data-img"));
			}	
		});
	}

	$('#ajax-tab-container').easytabs({updateHash: false});
	$(document).on('easytabs:ajax:complete', function(){
		if( $('.cycle-photo-slideshow').length > 0 ){
			initPhotoCycle();
			$(window).on('resize',function(){
				setGalControlHeight();
			});
		}
		if( $('#gallery-main-video').length > 0 ){
			initVideoCycle();
			addVidGalControls();
			$(document).on('click','#vid-gallery-prev, #vid-gallery-next',function(e){
				vidGalControl($(this));
			});
		}
		if($('.ctf-table').length > 0){
			ctfGridHeight();
			$(window).on('resize',function(){
				ctfGridHeight();
			});
		}
		if($('.rwd-accordion-wrapper').length > 0){
			initDrawers();
		}
	});
	
	if($('.stand-alone').length > 0){
		if( $('.cycle-photo-slideshow').length > 0 ){
			initPhotoCycle();
			$(window).on('resize',function(){
				setGalControlHeight();
			});
		}else if( $('#gallery-main-video').length > 0 ){
			initVideoCycle();
			addVidGalControls();
			$(document).on('click','#vid-gallery-prev, #vid-gallery-next',function(e){
				vidGalControl($(this));
			});
		}
	}
	
	$('#cruise-tabs-container').find('.cruise-tabs a').on('click',function(e){
		e.preventDefault();
		var tabHref = $(this).attr('href');
		var tabTarget = $(tabHref).offset().top;
		if($(window).scrollTop() < tabTarget){
			var headerHeight = $('#header').outerHeight();
			$('html, body').animate({scrollTop: tabTarget - headerHeight},500);
		}
	});
	
	if($('.cruise-tabs-inner').length > 0){
		equalizeCruiseTabs();
		$('.cruise-tabs-inner img').on('load',function() {
			// Added load event on imgs for safari browsers
            equalizeCruiseTabs();
        });
		$(window).on('resize',function(){
			equalizeCruiseTabs();
		});
	}
	
	if($('#cruisetour-itin-holder, .ctf-table').length > 0){
		ctfItinWidth();
		ctfGridHeight();
		
		$(window).on('resize',function(){
			ctfItinWidth();
			ctfGridHeight();
		});
	}
	
	$(document).on('click', '#video-pager a', function(e){
		e.preventDefault();
		loadVideo(e);
	});
	
	$('#view-itin-link').on('click', function(e){
		e.preventDefault();
		$('html, body').animate({scrollTop:$('#destinations-ports > h2').offset().top}, 'slow');
	});
	
	$(document).on('click', '.special-offers-holder > a, .special-offers-btn', function(e){
		e.preventDefault();

		//Closes magnific popup
		
		if( $.magnificPopup ){
			var magnificPopup = $.magnificPopup.instance.close();
		}
		else{
			//Close our lightbox
			hideLightbox();
		}

		hasSignedUpForSpclOffers = getCookie("SIGNED_UP_SPCL_OFFERS");
    	if(!(hasLoggedIn()) && !hasSignedUpForSpclOffers) {
       		if (loc=="UK" || loc=="GB"){
            	window.location = $bookURL + '/captaincircle/jsp/registrationStepOne.jsp';
        	}
        	else{
        		$('#header .special-offers a').trigger('click');
        	}
    	}else{
        	$('#header .special-offers a').trigger('click');
        }
	});
	
	var checkBrochureLink = setInterval(function(){
		var brochureLink = $('#get-brochure .brochure-holder a');
		if(brochureLink.length > 0){
			brochureLink.prop('href',getBookUrl(getUrl()) + 'captaincircle/brochureRequest.page');
			clearInterval(checkBrochureLink);
			checkBrochureLink = null;
		}
	},500);
	setTimeout(function(){
		if(checkBrochureLink){
			clearInterval(checkBrochureLink)
		}
	},5000);
		
	if($('#destinations-ports').length > 0){
		var checkStickyH2 = setInterval(function(){
			if($('#destinations-ports > h2').length > 0){
				clearInterval(checkStickyH2);
				var stickyH2 = $('#destinations-ports > h2');
				var destPorts = $('#destinations-ports');
				var stickyStart = destPorts.offset().top;
				var stickyEnd = destPorts.offset().top + destPorts.height();
				var stickyEndPosition = destPorts.height();
				
				setTimeout(function(){
					stickyStart = destPorts.offset().top;
					stickyEnd = destPorts.offset().top + destPorts.height();
					stickyEndPosition = destPorts.height();
				},500);
				
				$(window).on('resize',function(){
					stickyStart = destPorts.offset().top;
					stickyEnd = destPorts.offset().top + destPorts.height();
					stickyEndPosition = destPorts.height();
				});
				
				$('#destinations-ports').css('padding-top',stickyH2.outerHeight());
				
				$(window).on('scroll',function(){
					if(($(window).scrollTop() >= stickyStart)&&($(window).scrollTop() < stickyEnd)){
						stickyH2.addClass('sticky');
						stickyH2.css({
							'position':'',
							'top':''
						});
					} else if(($(window).scrollTop() >= stickyEnd) && ($(window).width() > 960)) {
						stickyH2.css({
							'position':'absolute',
							'top':stickyEndPosition
						});
					}else {
						stickyH2.removeClass('sticky');
					}
				});
			}
		},200);
	}
	
	initYoutubePopup();
		
	$('.ajax-popup-link').magnificPopup({
		type: 'ajax',
		removalDelay: 300,
		alignTop: true,
		closeMarkup: '<div class="mfp-close-wrapper"><div class="mfp-close-inner-wrapper"><button title="%title%" class="mfp-close"><i class="mfp-close-icn">Close</i></button></div></div>',
		mainClass: 'border-box-sizing',
		overflowY: 'scroll',
		callbacks: {
			beforeOpen: function() {

			},
			parseAjax: function(mfpResponse) {
				mfpResponse.data = $(mfpResponse.data).find('#popupContainer');
			},
			ajaxContentAdded: function() {
				// Ajax content is loaded and appended to DOM
				$('#ajax-tab-container').easytabs({updateHash: false});
				populateDropTab();
				initDrawers();
				initAddThis();
				runP13N();

			},
			open: function() {
				$('#feedbackify').fadeOut('fast');

				var item = this.currItem,
					container = this.container;
				
				lightboxOpenFocusFix(item, container);
			},
			close: function() {
				$('#container').removeAttr('aria-hidden');
				$('#feedbackify').fadeIn('fast');
			}
		}
	});
	
	var videoCarouselCheck = setInterval(function(){
		if($('#carousel').length > 0){
			clearInterval(videoCarouselCheck);
			var widthCat = windowCat($(window).width());
			var carouselNumVisible = 0;
			var carousel = $('#carousel')
			
			switch (widthCat) {
				case 'xs':
					carouselNumVisible = 1;
					break;
				case 'sm':
					carouselNumVisible = 2;
					break;
				case 'md':
					carouselNumVisible = 3;
					break;
				case 'lg':
					carouselNumVisible = 3;
					break;
			}
			
			carousel.find('> div').each(function() {
				if($(this).hasClass('video')){
					$(this).find('img').before('<div class="play_border"><div class="play_button"></div></div>');
				}
			});
			
			reinitCycle(carouselNumVisible);
			
			$(window).on('resize',function(){
				if(widthCat == 'xs'){
					if($(window).width() >= 640){
						widthCat = windowCat($(window).width());
						// reinit carousel with 2 items in view
						carousel.cycle('destroy');
						reinitCycle(2);
					}
				} else if (widthCat == 'sm'){
					if($(window).width() < 640){
						widthCat = windowCat($(window).width());
						// reinit carousel with 1 items in view
						carousel.cycle('destroy');
						reinitCycle(1);
					} else if ($(window).width() >= 768) {
						widthCat = windowCat($(window).width());
						// reinit carousel with 3 items in view
						carousel.cycle('destroy');
						reinitCycle(3);
					}
				} else if (widthCat == 'md') {
					if($(window).width() < 768){
						widthCat = windowCat($(window).width());
						// reinit carousel with 2 items in view
						carousel.cycle('destroy');
						reinitCycle(2);
					} else if ($(window).width() >= 992) {
						widthCat = windowCat($(window).width());
						// no reinit
					}
				} else if (widthCat == 'lg') {
					if($(window).width() < 992){
						widthCat = windowCat($(window).width());
						// no reinit
					}
				}
			});
		initYoutubePopup();
		}
	},1000);
	
	if($('#popupContent').find('.rwd-accordion-wrapper').length > 0){
		initDrawers();
	}
});

function equalizeCruiseTabs(){
	var ctH3 = $('.cruise-tabs-inner h3'),
		buttonHolder = $('.button-holder'),
		bulletsDiv = $('.bullets');
	
	if($(window).width() >= 992){

		var ctH3Height = 0,
			buttonHolderHeight = 0,
			bulletsDivHeight = 0;
		
		ctH3.css('height','auto');
		ctH3.each(function() {
            if($(this).outerHeight() > ctH3Height){
				ctH3Height = $(this).outerHeight();
			}
        }).css('height',ctH3Height);
		
		buttonHolder.css('height','auto');
		buttonHolder.each(function() {
            if($(this).outerHeight() > buttonHolderHeight){
				buttonHolderHeight = $(this).outerHeight();
			}
        }).css('height',buttonHolderHeight);
		
		bulletsDiv.css('height','auto');
		bulletsDiv.each(function() {
			if($(this).outerHeight() > bulletsDivHeight){
				bulletsDivHeight = $(this).outerHeight();
			}
		}).css('height',(bulletsDivHeight + buttonHolderHeight));
	}else{
		buttonHolder.css('height','auto');
		bulletsDiv.css('height','auto');
	}
}


function ctfItinWidth(){
	var cruisetourItinHolder = $('#cruisetour-itin-holder');
	if($(window).width() >= 768){
		var blocks = cruisetourItinHolder.find('.blocks .ctf-block');
		var n = 0;
		blocks.each(function() {
			if($(this).hasClass('ctf-block-2')){
				n = n + 2;
			} else {
				n++;
			}
		});
		$('#cruisetour-itin-holder .ctf-block-2').css('width',((100 / n) * 2)+'%');
		$('#cruisetour-itin-holder .ctf-block-1').css('width',((100 / n))+'%');
	} else {
		var days = cruisetourItinHolder.find('.days .ctf-block'),
			blocks = cruisetourItinHolder.find('.blocks .ctf-block');
			
		days.each(function() {
			var index = $(this).index();
			$(this).height(blocks.eq(index).height());
		});
		$('#cruisetour-itin-holder .ctf-block-2').css('width','auto');
		$('#cruisetour-itin-holder .ctf-block-1').css('width','auto');
	}

}

function ctfGridHeight(){
	$('.ctf-feature-cell, #cruisetour-itin-holder > .blocks').each(function() {
        var blocks = $(this).children();
		var h = 0;
		if($(this).hasClass('ctf-feature-cell') == true){
			if($(window).width() < 768){
				h = 'auto';
			} else {
				h = $(this).height();
			}
		} else {
			blocks.each(function() {
				if($(this).outerHeight() > h){
					h = $(this).outerHeight();
				}
			});
		}
		blocks.css('height',h);
    });
}

function changeLoc(e){
	window.location.href = e;
}

function initPhotoCycle(){
	$('.cycle-photo-slideshow').cycle();
	$('.cycle-photo-slideshow').on( 'cycle-before', function(e, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag ) {
		nextImg = $('.cycle-photo-slideshow img').eq(optionHash.nextSlide + 1);
		if ( nextImg.attr("src") == "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=="){
			nextImg.attr("src", nextImg.attr("data-img"));
		}
	});
	$('#gallery-main-img').on('load',function(){
		setGalControlHeight();
	});
}

function setGalControlHeight(){
	$('.gallery-controls').css('top',$('#gallery-main-img').outerHeight()/2);
}

function initVideoCycle(){
	setTimeout(function(){$('#video-pager div:first-child > a').trigger('click')},500);
}

function addVidGalControls(){
	if($('#video-pager a').length > 1){
		$('#gallery-main-video').before('<div class="vid-gallery-controls"><span id="vid-gallery-prev" class="vid-gallery-control-prev"><span class="arrow">Previous</span></span><span id="vid-gallery-next" class="vid-gallery-control-next"><span class="arrow">Next</span></span></div>');
		$('#gallery-main-video iframe').on('load',function(){
			setVidGalControlHeight();
		});
	}
}

function setVidGalControlHeight(){
	$('.vid-gallery-controls').css('top',$('#gallery-main-video').outerHeight()/2);
}

function loadVideo(e){
	$('#gallery-main-video > iframe').prop('src','//www.youtube.com/embed/'+$(e.currentTarget).data('video-id')+'?rel=0&showinfo=0&autohide=1&wmode=transparent');
	$('#gallery-main-video').data('video-id',$(e.currentTarget).data('video-id'));
	$('#video-title').html($(e.currentTarget).find('.title').html());
	$('#video-copy').html($(e.currentTarget).find('.hidden-video-copy').html());
}

function vidGalControl(e){
	var videoID = e.closest('div').parent().find($('#gallery-main-video')).data('video-id');
	var targetDiv = $('#video-pager').find('[data-video-id="'+videoID+'"]').parent();	
	if(e.hasClass('vid-gallery-control-prev')){
		targetDiv.prev().find('a').trigger('click');
	}else{
		targetDiv.next().find('a').trigger('click');
	}
}

function windowCat(width) {
	if(width < 640)return 'xs';
	if(width >= 640 && width < 768)return 'sm';
	if(width >= 768 && width < 992)return 'md';
	if(width >= 992)return 'lg';
}

function reinitCycle(visibleSlides) {
	$('#carousel').cycle({
		fx: 'carousel',
		slides: '> div',
		prev: '#carousel-prev',
		next: '#carousel-next',
		carouselVisible: visibleSlides,
		carouselFluid: true,
		timeout: 0,
		log: false
	});
}

function initAddThis(){
	if (window.addthis) {
		addthis.toolbox('.addthis_toolbox');
		addthis.counter('.addthis_counter');
	}
	else{
		$.getScript('//s7.addthis.com/js/250/addthis_widget.js#pubid=ra-4fc673615553b316#async=1');
	}
}

function initYoutubePopup(){
	$('.popup-youtube').magnificPopup({
		type: 'ajax',
		removalDelay: 300,
		alignTop: true,
		closeMarkup: '<div class="mfp-close-wrapper"><div class="mfp-close-inner-wrapper"><button title="%title%" class="mfp-close"><i class="mfp-close-icn">Close</i></button></div></div>',
		mainClass: 'border-box-sizing',
		overflowY: 'scroll',
		callbacks: {
			beforeOpen: function() {
				if( $($.magnificPopup.instance.st.el).closest('.slideshow-play').length > 0 && window.location.href.indexOf('/come-back-new') > -1){
					var axel = Math.random() + '';
					var a = axel * 10000000000000;
					var tag_url='http://3182906.fls.doubleclick.net/activityi;src=3182906;type=butto442;cat=click761;ord=' + a + '?';
					
					if(document.getElementById("DCLK_FLDiv")){
						var flDiv=document.getElementById("DCLK_FLDiv");
					}
					else{
						var flDiv=document.body.appendChild(document.createElement("div"));
						flDiv.id="DCLK_FLDiv";
						flDiv.style.display="none";
					}
					var DCLK_FLIframe=document.createElement("iframe");
					DCLK_FLIframe.id="DCLK_FLIframe_"+Math.floor(Math.random()*999999);
					DCLK_FLIframe.src=tag_url;
					flDiv.appendChild(DCLK_FLIframe);
				}
			},
			parseAjax: function(mfpResponse) {
				mfpResponse.data = $(mfpResponse.data).find('#popupContainer');
			},
			ajaxContentAdded: function() {
				// Ajax content is loaded and appended to DOM
				initAddThis();
				runP13N();
			}
		}
	});
}

function initDrawers(){
	if($('.rwd-accordion-wrapper').length > 0){
		accordion.init({
			'wrapper' : '.rwd-accordion-wrapper',
			'item' : '.rwd-accordion',
			'header' : '.rwd-accordion-header',
			'content' : '.rwd-accordion-content',
			'autoScroll' : false
		});
	}
}